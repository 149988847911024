@import '../../sass/index.scss';

.mensaje_ini{
  width:60%;
  padding:25px;
  font-size:28px;
  ol{
    margin-left:25px;
  }
}

.titulo_ini{
  width:60%;
  padding:25px;
  font-size:32px;
  font-weight:bold;
  line-height:28px;
}
.mensaje-popup{
  width:600px;
  height:600px;
  background-size:cover;
}

.contenedor-mensaje {
  color:#fff;
  font-family:'Poppins';
  padding-top: 120px;
  padding-bottom: 50px;
}

@media (max-width: 414px) {
  .mensaje-popup {
    width:90%;
    height:auto;
    background-size:cover;
  }
  .mensaje_ini{
    width: 100%;
  }
  .titulo_ini{
    width: 100%;
  }
  .contenedor-mensaje {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  
}