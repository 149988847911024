@import "../../sass/index.scss";

.carousel-btn-container{
    position: relative;
    color:$primary;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11rem;
    font-size: 2rem;
    @media (max-width: 545px){
        justify-content: space-between;
    }
    p{
        font-size: 2rem;
    }
    .arrow-icons{
        z-index: 1;
        margin: auto 10px;
        &:hover{
            cursor: pointer;
            font-size: 2.1rem;
        }
    }
}