.container-review {
    position: relative;
    z-index: 1;
    height: 90vh; /* Asegura que el contenedor tenga altura */
    @media(max-width: 820px){
        height: 85vh; /* Asegura que el contenedor tenga altura */
    }
    @media(max-width: 770px){
        height: 85vh; /* Asegura que el contenedor tenga altura */
    }
    @media (max-width: 430px) {
        height: 105vh; /* Asegura que el contenedor tenga altura */
    }
    @media (max-width: 390px) {
        height: 110vh; /* Asegura que el contenedor tenga altura */
    }
    @media (max-width: 375px) {
        height: 140vh; /* Asegura que el contenedor tenga altura */
    }
    @media(max-width: 360px){
        height: 125vh; /* Asegura que el contenedor tenga altura */
    }
    
}

.background-container-review {
    background-image: url('/images/fondo_review.png'); /* Ruta desde public */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute; /* Asegura que el fondo cubra todo el contenedor */
    top: 0;
    left: 0;
    height: 100%; /* Ajusta según el tamaño del contenedor */
    width: 100%; /* Asegura que ocupe todo el ancho */
    z-index: -1; /* Coloca el fondo detrás del contenido */

    
}

.contenido-review {
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 600px; /* Ajusta este valor según tus necesidades */
    width: 100%; /* Asegura que ocupe todo el ancho disponible hasta el máximo */
    top: 10%; /* Ajusta según el tamaño del contenido */
    left: 30%; /* Ajusta según el tamaño del contenido */
    @media (max-width: 430px) {
        top: 5%;
        left: 15%;
        bottom: 10px;
        width: 300px;
        height: 40px;
        padding: 0;
    }
    @media(max-width: 770px){
        left: 11%;
        top: 15%;
    }
    @media(max-width: 820px){
        left: 12%;
        top: 15%;
    }
    @media (max-width: 430px) {
        left: 13%;
        top: 5%;
    }
    @media (max-width: 360px) {
        left: 10%;
    }

    
}

.review-title{
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    h1{
        font-weight: 550;
        transform: scale(1, 1.05); /* Escala en el eje Y (altura) */
        letter-spacing: 1.2px;
    }
}

.subtitle-review{
    display: flex;
    margin-bottom: 18px;
    align-items: center;
    margin-left: 15px;
    img{
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
    p{
        margin-left: 10px;
        font-size: 18px;
        font-weight: 200; /* Utiliza un peso ligero para hacer el texto más delgado */
        transform: scale(1, 1.2); /* Escala en el eje Y (altura) */
        font-stretch: ultra-condensed;
    }
}

.review-step{
    background-color: #f4f4f8;
    color: #1c56ff;
    border-radius: 15px;
    padding: 18px;
    margin: 30px auto;
    max-width: 570px;
    border: 1px solid black;
    box-shadow: 0 4px 8px rgba(146, 146, 146, 0.3);
    h3{
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 500;
        margin-bottom: 5px;

    }
    p{
        font-size: 17px;
        transform: scale(1, 1.1); /* Escala en el eje Y (altura) */
        font-weight: 300;
        line-height: 1.2;
    }
}

.review-buttons{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 20px;
    .facebook-button{
        color: #f4f4f8;
        border: solid 1px #f4f4f8;
        border-radius: 10px;
        padding: 13px 30px;
        display: flex; 
        align-items: center;
        text-decoration: none;
        img{
            width: 30px;
            height: 30px;
            margin-right: 6px;
        }
        p{
            color: #f4f4f8;
            font-weight: 500;
            font-size: 18px;
            transform: scale(1, 1.1); /* Escala en el eje Y (altura) */
        }
        &:hover{
            background-color: #1c56ff;

        }
        
        @media (max-width: 500px) {
            .review-text{
                display: none;
            }
        }
    }
    
    .whatsapp-button{
        background-color: #1c56ff;
        border-radius: 10px;
        padding: 13px 30px;
        display: flex; 
        align-items: center;
        text-decoration: none;
        img{
            width: 30px;
            height: 30px;
            margin-right: 6px;
        }
        p{
            text-decoration: none;
            color: #f4f4f8;
            font-weight: 500;
            font-size: 18px;
            transform: scale(1, 1.1); /* Escala en el eje Y (altura) */
        }
        &:hover{
            background-color: #1cd116;

        }

        @media (max-width: 500px) {
            .review-text{
                display: none;
            }
        }   
    }
}

.fade-enter {
    opacity: 0;
    transform: translateY(-20px);
}
.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}
.fade-exit {
    opacity: 1;
    transform: translateY(0);
}
.fade-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms, transform 300ms;
}


