@import "../../sass/index.scss";

.InformacionInstitucional {
  .frase {
    blockquote {
      color: $color-primary;
      font-family: Tahoma, sans-serif;
      font-size: 30px;
      width: 74%;
      margin: 40px auto;
      text-align: center;
      & h1 {
        font-size: 4rem;
      }
      & p {
        font-style: italic;
        margin-bottom: 0;
        font-size: 19px;
        font-weight: 300;
        text-align: center;
      }

      & p::before,
      & p::after {
        content: "“";
        font-family: Georgia;
        font-size: 3rem;
        margin: -1rem 0 0 -1.5rem;
        position: absolute;
        opacity: 0.5;
      }

      & p::after {
        content: "”";
        margin: -0.8rem -4rem 0 0;
      }

      & cite {
        font-size: 0.8rem;
      }
    }
  }
  .vision-mision {
    .titulo {
      font-size: .9rem;
      font-weight: 600;
      display: block;
      margin: 15px 0 8px 0;
    }
  }
}
