@import "../../sass/index.scss";

@import "../../sass/colores.css";

.card-body {
	position: relative;
}
.card-course{
    height: 430px;
    border-radius: 25px;
	border: none;
	.card-bg-img{
		border-radius: 25px 25px 0 0;
	}
	.body-course{
        display: flex;
        flex-direction: column;
		align-items: center;
		justify-content: start;
        max-height: 300px;
		padding: 10px;
		.titulo_curso{

			margin: auto 0;
			text-align: center;
            font-size: 15px;
            line-height: 16px;
            font-weight: 500 !important;
		}
        
        .date-container{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: auto;
            .calendar-container {
                display: flex;
                padding: 0;
                .calendar-icon {
                    max-width: 20px;
                    margin-right: 10px;
                }
            }
            p{
                color: $primary;
                font-weight: 500;

                
            }
        }
        
	}
    
    .footer-course{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $full-light;
        border-radius: 0 0 35px 35px;
        .card-actions{
            display: flex;
            @include btn-degrade;
        }
        .avatars-container{
            align-items: center;
        }
    }
    
    &:hover{
        -webkit-box-shadow: 2px 3px 11px -5px rgba(0,0,0,0.75);
        -moz-box-shadow: 2px 3px 11px -5px rgba(0,0,0,0.75);
        box-shadow: 2px 3px 11px -5px rgba(0,0,0,0.75);
        
    }
}

.MuiAvatarGroup-root {
	position: absolute;
	bottom: 7px;
}
.MuiAvatar-root {
	width: 30px !important;
	height: 30px !important;
}
.fon_modulo_pre {
	min-height: 170px;
}

.btn_modulos.boton-curso,
.boton-curso {
	background: $primary;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
    border-radius: 15px;
}

.btn_modulos.boton-curso:hover {
	color: #fff;
	background: var(--main-success-color);
}

@media (max-width: 768px) {
	.boton-curso.boton-responsive {
		background-color: $primary;
		/* width: 100%; */
		/* display: none; */
	}
	.titulo_curso {
		font-size: 13px;
		line-height: 16px;
		font-weight: 500 !important;
	}
	.text-curso {
		font-size: 11px;
	}
	.card-curso {
		padding: 0 15px;
	}
	.card-body {
		padding-left: 0;
		padding-right: 0;
	}
	.detalles_curso {
		margin-top: 10px;
	}
	.MuiAvatarGroup-root {
		margin-top: 25px;
		position: inherit;
	}
}

@media (min-width: 1024px ) and (max-width: 1410px) {
    .footer-course{
        display: flex;
        flex-direction: column;
        .avatars-container{
            .MuiAvatarGroup-root{
                position: static;
            }
        }
    }
}

@media (min-width: 464px ) and (max-width: 729px) {
    .footer-course{
        display: flex;
        flex-direction: column;
        .avatars-container{
            .MuiAvatarGroup-root{
                position: static;
            }
        }
    }
}

.user-message {
	width: 50px;
	height: 50px;
	display: block;
	border: 1px solid #dfdfdf;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 50%;
	background-image: url("/images/icono-usuario.png");
}


/* Proyecto.js */
