.alert-danger {
  background-color: #F8D7DA;
  color: #78281F;
  border: 1px solid lighten($color: $danger,$amount: 30);
  border-radius: 0;
  font-size: .8rem;
  @media (min-width: 1400px) {
    & {
      font-size: 1rem;
    }
  }
}