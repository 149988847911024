@import '../../sass/index.scss';

.notification-container .notification {
  background: transparent;
  box-shadow: none;
}

.notification-container .notification::after {
  top: -3px;
  right: -5px;
  padding: 2px 5px;
}


.popover .popover-header{
  color:#000;
}

.popover .btn{
  color: #000;
}

.Header {
  // z-index: 2000;
  max-height: 80px;
  width: 100%;
  background-color: $bg-primary;
  color: #fff;
  padding: 15px;
  // position: fixed;
  @media (max-width: 614px) {
    max-height: 90px;
    padding: 10px 13px;
  }
  img {
    width: 120px;
  }

  .AccionesAvatar {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    justify-content: space-between;
    li {
      list-style: none;
      margin: 0 10px;
      &:hover{
        cursor: pointer;
        border-bottom: 0.5px solid $body-bg-secondary;
      }
    }
    svg {
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      &:first-child {
        margin-left: 15px;
      }
    }
    .MuiBadge-badge{
      &.MuiBadge-dot{
        border: 1px solid #fff;
      }
      &.MuiBadge-colorPrimary{
        background-color: #2b0462;
      }
    }
    .icon-message svg {
      font-size: 23px;
    }
    .img_perfil {
      width: 50px;
      height:50px;
      border-radius: 50%;
    }
  }
}