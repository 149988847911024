@import '../../../sass/index.scss';

.FormLogin {
  input, button {
    height: 3.5em;
    border: 0;
    border-radius: 10px;
  }
  label, button {
    font-size: .8rem;
    font-weight: 500;
    text-transform: uppercase;
    @media (min-width: 1400px) {
      & {
        font-size: 1rem;
      }
    }
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-group:last-child {
    margin-bottom: 0;
  }
  button {
    height: 3em;
    margin-top: 30px;
    text-transform: uppercase;
  }
  .form-control {
    background: #F2F5FA;
    padding: 0 20px;
    color: $color-primary;
    font-weight: 400;
    font-size: .8rem;
    border: 1px solid #F2F5FA;
    transition: all .3s ease;
    @media (min-width: 1400px) {
      & {
        font-size: 1rem;
      }
    }
    &::placeholder {
      color: lighten($color: $color-primary, $amount: 2);
    }
    &:focus {
      box-shadow: 0 0 1px 3px #DEE3F8;
    }
    &:active, &:focus {
      text-shadow: 0;
      outline: none !important;
      outline-width: 0 !important;
    }
    
  }
}