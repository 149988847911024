.btn {
  &:active, &:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none !important;
  }
}

.btn {
  font-size: .7rem;
}

.btn-success {
  background-color: $success;
}
.btn.btn-primary {
  background-color: $primary;
  border: 0;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($color: $primary, $amount: 5) !important;
  }
}
.btn-danger{
  background-color: #1C56FF;
  border:0;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($color: $danger, $amount: 5) !important;
  }
}
