.cabecera_podcast{
    background-image: url('/images/pc_header.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height:280px;
    overflow: hidden;
}

.img_pd{
    width:70px;
    height:70px;
    border-radius: 50%;
}

.reproductor{
    position: fixed;
    background-color:#1C56FF;
    bottom:0;
    left: 0;
    height:auto;
    width: 100%;
    z-index: 1000;
}
.datos-podcast{
    color:#fff;
}
.display-block{
    display:  block;
    animation: fade_in_show 0.5s;
}

@keyframes fade_in_show {
    0% {
         opacity: 0;
    }

    100% {
         opacity: 1;
    }
}



.playerWrapper{
    width:100%;
    position: relative;
}
.controlsWrapper{
    position: absolute;
    top:12px;
    left:0;
    width:100%;

}

@media (max-width:450px) {
    .reproductor{
        height:180px;
    }
    .padding-bottom-movil{
        padding-bottom: 120px;
    }
}