@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700;800;900&display=swap");
@import './sass/index.scss';
@import './sass/base';
/*
* COMPONENTES
*/

@import './sass/components/badge';
@import './sass/components/alerts';
@import './sass/components/botones';
@import './sass/components/accordion';
@import './sass/components/tabla';

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: .8rem;
}


/*EVENTO*/
p{
	font-size: 13px;
}

.font-10{
  font-size: 10px !important;
}
.font-15{
  font-size: 15px !important;
}
.font-20{
  font-size: 20px !important;
}

.text-success {
  color: $success !important;
}

.text-danger {
  color: $danger;
}

.cuadro{
	display: flex;
	align-content: center;
}

/* Icons with PNG format */
.img-icons{
  @media screen and (max-width: 425px) {
    max-width: 13px;
  }
  max-width: 23px;
}

/*SpinKit*/
.spinner {
  width: 20px;
  text-align: center;
}

.spinner > div {
  width: 5px;
  height: 5px;
  background-color: #856404;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}


@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}

.dropdown-toggle::after{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    color: #fff;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

/*SESIONES*/
.tab-a{
  color: $color-primary;
  width: 100px;
}
.nav-link.tab-a.d-block.active.show{
  background: $danger;
  color: #eee;
}
.t-seciones {
  font-family: "Poppins",Helvetica,Arial,sans-serif;
}
.t-seciones thead{
  font-size: 13px;
}
h4.title-sesiones{
  font-size: 15px;
  font-family: "Poppins",Helvetica,Arial,sans-serif;
}

.btn_datos input{
  margin: 10px;
}
.card_curso{
  min-height: 200px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.07);
  }
.btn_modulos{
  float: right;
}

.fon_modulo{
  width: 100%;
  height: 100%;
  position: relative;
  background:rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
}
.fon_modulo link:hover .fon_modulo{
  background:rgba(0,0,0,0.9);
}
.content_card{
  height: 100%!important;
  
}
.btn_modulos{
  float:none;
  margin: auto !important;
  height: 40px;
  line-height: 27px;
  box-shadow: 0px 0px 10px 0px black;
}
.fon_modulo_pre{
 
  background-image: url("/images/proyectos-img.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}



/*EDITOR*/
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused{
  min-height: 200px;
}
/*SPINER */

/*NAV*/
.navbar-nav.mr-auto.mt-2.mt-lg-0.container.px-4{
    justify-content: start;
}



.hover-light{
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
}


/*Cursores*/
.cursor-pointer{
  cursor: pointer !important;
}


/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/


/*CSS*/
.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1); 
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2C3E50;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
          animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; 
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  } 
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0; 
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1; 
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0; 
  }
}

.seleccion .css-yk16xz-control, .seleccion .css-1pahdxg-control{
  min-height: 90px !important;
  align-items: start !important;
}

/*BORDE DE ENLACES DE RECURSOS*/
.nombre-archivo.p-3 a:hover{
  text-decoration: underline rgb(103, 142, 206);
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.embed-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-individual iframe{
  width: 100%;
}

$base-color-pag: #ced4da;
$light-background-pag: lighten(desaturate($base-color-pag, 50%), 12.5%);

ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  
  li.page-item.active {
    a.page-link {
      color: saturate(darken($base-color-pag, 50%), 5%) !important;
      background-color: saturate(lighten($base-color-pag, 7.5%), 2.5%) !important;
      border-color: $base-color-pag !important;
    }
  }

  a.page-link {
    text-align: center;
    box-shadow: none !important;
    border-color: $base-color-pag !important;

    &:hover {
      background-color: $light-background-pag;
    }
  }
}
.p-nombre-video:hover{
  text-decoration: underline rgb(103, 142, 206);
  cursor: pointer;
}

/*TRANSITION GROUP*/
/*MENSAJERIA*/
.panel{
  position: relative;
  width: 100%;
}
.panel-enter{
  transition:.5s;
  transform: translateX(5%);
}

.panel-enter.panel-enter-active{
  transform: translateX(0%);
}
.panel-exit{
  transform: translateX(5%);
  transition: .5s;
  position: absolute;
  top: 0;
  left: 0;
  
  right: 0;
}
.panel-exit-active{
  opacity: 0;
  transform: translateX(0%);
}


/*Stilos nav*/
@media(min-width: 250px){
  .navbar-nav.ml-lg-auto{
    flex-direction: row !important;
    justify-content: flex-end !important;
  }
}

.dropdown-toggle::after{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: none;
  color: #fff;
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}