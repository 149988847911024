@import '../../sass/colores.css';

.cv_docente{
  padding:18px;
}


.acordeon-recursos h6 {
  font-size: .8rem;
}
.card-info-sesion .title-detalle{
    color: var(--main-primary-color);
}

.card-info-sesion .card{
    background: #FCFCFC;
}
.card-info-sesion .card-body .nombre-sesion {
    color: var(--main-danger-color);
    font-size: 15px;
}
.card-info-sesion .text-profesor{
    color: var(--main-info-color);
}
.card-info-sesion p{
    color: #333;
}

.card-info-sesion .text-horario{
    color: var(--main-success-color);
}

.card-info-sesion .fecha-sesion{
    color: var(--main-success-color);
}
.card-info-sesion .card-body .dia-horario{
    text-decoration: none;
    list-style: none;
}

.acordeon-recursos .mensajes-info {
  font-size: 13px;
  margin-bottom: 0;
}

/*Tareas tabla*/
#modal-tareas .botones {
    margin: 0 0 10px 0;
    /* border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px; */

}

#modal-tareas .botones button {
    background: transparent;
    border: 1px solid #ccc;
    margin-right: 5px;
    color: #fff;
}
#modal-tareas .botones button:hover {
  background: #EDEDED;
  border: 1px solid #ccc;
  color: #fff;
}
#modal-tareas .aviso-cargando{

  display: flex;
  justify-content: flex-start;
}
.sk-chase {
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 10px;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
  -webkit-animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--main-primary-color);
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
  -webkit-animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}

#modal-tareas .modal-body{
    min-height: 500px;
}


@media (max-width: 414px) {
  #modal-tareas .modal-body{
    min-height: 300px;
}
}

#modal-tareas .tabla-cuenta thead tr th {
    padding: 7px 5px;
    font-size: 13px;
  }
  #modal-tareas .tabla-cuenta tbody td {
    padding: 7px 5px;
  }
  #modal-tareas .tabla-cuenta tbody td p {
    font-size: 12px;
    font-weight: 400 !important;
  }
  #modal-tareas .tabla-cuenta tbody td p.text-info:hover {
    text-decoration: underline;
  }
  #modal-tareas .thead-cuenta{
    background: var(--main-primary-color);
  }
  #modal-tareas .img-enlaces{
    display: flex;
    flex-direction: row;
  }
  #modal-tareas a img {
    width: 100px;
    border-radius: 15px;
    margin-right: 5px;
  }