@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.plafon{
    position: relative;
    margin: 10px auto 30px;
    text-align: center;
    width: 720px;
}

.ruleta{
    width: 100%;
    max-width: 500px;
    height: auto;
    aspect-ratio: 1;
    background-size: cover;
    background-position: center;
    margin: 0 auto 15px;
}

.central{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 140px;
    top: 180px;
}
.central img{
    width: 100%;
}

.boton-ruleta {
    background-color: #25d366; /* Color verde */
    color: white; /* Color del texto */
    padding: 7px 140px; /* Espaciado interno */
    text-align: center; /* Alineación del texto */
    text-decoration: none; /* Sin subrayado */
    display: inline-block; /* Para que funcione el padding */
    font-size: 24px; /* Tamaño de la fuente */
    border: none; /* Sin borde */
    border-radius: 10px; /* Bordes redondeados */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
    transition: background-color 0.3s; /* Transición suave del color de fondo */
}

.boton-ruleta:hover {
    background-color: #45a049; /* Color de fondo al pasar el ratón */
}


.parate{
    animation-play-state: paused;
}
@keyframes animacionBarra{
    0%{
        width: 0%;
    }
    50%{
        width: 100%;
    }
    100%{
        width: 0%;
    }
}

/*Estilos Modal*/


/* Estilos para el Modal */

.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 12px;
    padding: 0 15px;
    width: 90%;
    max-width: 500px;
    height: auto;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    opacity: 0;
}

.Modal .modal-content {
    border: none; /* Elimina bordes del contenido del modal */
}

.Modal .modal-header, .Modal .modal-footer {
    border: none; /* Elimina bordes del header y footer */
    padding-bottom: 0;
}

.Modal .modal-body {
    padding: 0; /* Ajusta el padding del body */
}

.Modal.fade-out {
    animation: fadeOut 0.4s ease-out forwards;
}

.Modal.fade-in {
    animation: fadeIn 0.4s ease-out forwards;
}



/* Animación de entrada */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Animación de salida */
@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.9);
    }
}

/* Estilos del título */
.Modal h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
}

/* Estilos para el texto */
.Modal p {
    font-size: 24px;
    color: #1d56fe;
    margin-bottom: 20px;
    font-weight: bold;
}

/* Botón de cierre */
.Modal button {
    background-color: #1d56fe;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.Modal button:hover {
    background-color: #1d3d9e;
}






/* Contenedor de la imagen */
.image-container {
    position: relative;
    display: inline-block;
    width: 100%; /* Ajusta según el tamaño que desees */
    max-width: 300px; /* Ajusta el tamaño máximo si lo necesitas */
    margin: 15px auto;
}

/* Imagen dentro del contenedor */
.image-container img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px; /* Para bordes redondeados */
    
}

/* Texto superpuesto */
.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-5deg);
    color: #1d56fe;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding: 5px 10px;
    border-radius: 4px; /* Opcional, para bordes redondeados */
}


.affiliate-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .box-image img {
    max-width: 150px;
  }
  
  .affiliate-title {
    font-size: 1.5rem;
    font-weight: 400;
    color: white;
  }
  
  .highlight-afiliate{
    font-weight: bold;
    font-size: 1.8rem;
  }
  
  .input-group .form-control {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    text-align: center;
  }
  
  .input-group .form-control::placeholder {
    color: white;
  }
  
  .affiliate-button {
    background-color: #1a6afe;
    border: none;
    font-weight: bold;
    font-size: 14px;
    color: white;
    padding: 15px 60px;
  }

/* Para pantallas medianas */
@media (max-width: 768px) {
    .plafon {
        width: 100%;
    }
    
    .ruleta {
        max-width: 300px;
    }
    
    .central {
        width: 80px;
        top: 110px;
    }
    
    .boton-ruleta {
        padding: 10px 20px;
        font-size: 16px;
    }
    .affiliate-button{
        display: none;
    }

    .affiliate-title{
        padding: 0 20px;
    }
}
  