@import '../../../sass/index.scss';

.BasicModal {
  .modal-header {
    background-color: $primary;
    color: #fff;
    .h4 {
      font-size: 1.2rem;
    }
  }
  .modal-footer {
    background-color: $light;
  }
}