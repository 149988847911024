.floating-review-button {
    position: fixed;
    bottom: 40px;
    left: 40px;
    background-color: #0084ff;
    border-radius: 10px;
    padding: 3px 10px;
    box-shadow: 0 4px 8px rgba(146, 146, 146, 0.3);
    z-index: 999;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 14px;

    &:hover {
      text-decoration: none;
      color: white; 
    }

    @media (max-width: 770px) {
      left: 10px;
      bottom: 10px;
      width: 40px;
      height: 40px;
      padding: 4px;
      .review-text {
        display: none;
      }
    }
  }
  
  .review-icon {
    width: 50px !important;
    height: 38px !important;
    margin-right: 5px !important;
  }
  
  .review-text {    
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 500;
    margin-right: 5px;
  }
  