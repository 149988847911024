@import '../../../sass/index.scss';

.tabla-calificaciones {
  .tabla {

    thead {
      tr {
        background-color: $primary;
        color: #fff;
        th {
          font-weight: 500;
          font-size: 13px;
          padding: 8px 10px;
        }
      }
    }
    tbody, tbody p {
      font-size: 12px;
      tr {
        td {
          padding: 8px 10px;
        }
        .td-modulo {
          min-width: 240px;
          padding: 0;
          .content-nombre-modulo {
            padding: 0 10px;
          }
        }
        .td-nombre-sesion {
          min-width: 250px;
        }
      }
    }
    .button-detalle{
      background-color: #685fff;
      color: white;
    }
    .button-detalle:hover{
      background-color: #353091;
      color: white;
    }
  }
  
}