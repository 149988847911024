/* :root{
    --main-primary-color: #27314A;
    --main-secondary-color: #3A4C7E;
    --main-success-color: #18BB60;
    --main-info-color: #3497DB;
    --main-warning-color: #F3C301;
    --main-danger-color: #FE0000; 
} */

:root{
    --main-primary-color: #1C56FF;
    --main-secondary-color: #3A4C7E;
    --main-success-color: #18BB60;
    --main-info-color: #3497DB;
    --main-warning-color: #F29F06;
    --main-danger-color: #1C56FF;
}