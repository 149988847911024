@import '../../sass/index.scss';


.toast-content-enter {
  opacity: 0;
  transition: all 300ms ease;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
}
.toast-content-enter.toast-content-enter-active {
  opacity: 1;
  transition: all 300ms ease;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}
.toast-content-exit {
  opacity: 1;
}
.toast-content-exit-active {
  opacity: 0;
  transition: all 3000ms ease;
  -webkit-transition: all 3000ms ease;
  -moz-transition: all 3000ms ease;
  -ms-transition: all 3000ms ease;
  -o-transition: all 3000ms ease;
}

.toast {


  background-color: $light;
  color: $primary;
  z-index: 2;
  max-width: 400px;
  
  
  
  &-header {
    background-color: $light;
    color: $primary;
    p {
      font-weight: 500;
    }
    button span {
      color: $primary;
    }
  }
  &-body {
    img {
      width: 70px;
      height: 50px;
      margin-right: 8px;
    }
    p {
      font-weight: 500;
    }
    a {
      font-size: .8rem;
    }
  }
}

@media (max-width: 414px) {
  .toast {
    right: .5rem;
  }
}