
.contenido-tabs .nav-tabs .active{
    color: #fff;
    background: #1C56FF;
}

.contenido-tabitem {
    height:auto;
    color: #000;
    background-color: #EFEFEF;
    width: 120px;
    font-size: 14px;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenido-tabitem:active {
    color: #fff;
    background: #1C56FF;
}
