@import '../../../sass/index.scss';

.CardContador {
  border: 1px solid #e4e4e4;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  text-align: center;
  &__title {
    color: $secondary;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 15px;
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-big {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    &.primary {
      background-color: $primary;
    }
    &.info {
      background-color: $info;
    }
    &.secondary {
      background-color: $secondary;
    }
    &.success {
      background-color: $success;
    }
    svg {
      margin-top: 15px;
      font-size: 30px;
      color: #fff;
      text-rendering: auto;
    }
  }
  .numbers h2 {
    font-size: 2.6rem;
    font-weight: 500;
    color: $color-primary;
  }
}