.container-cupon {
    text-align: center;
    max-width: 600px;
    border-radius: 8px;
    position: relative;
    margin: 0 auto;
    transform: translateY(-20%); /* Ajusta la posición horizontal */
}
.highlight {
    color: #3366ff;
    font-weight: bold;
}
.ticket-text {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%)rotate(-3deg);
    color: #1c56ff;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 80%;
    max-width: 250px; /* Ajusta el ancho máximo según sea necesario */
    word-wrap: break-word; /* Permite que las palabras largas se dividan */
    white-space: normal; /* Permite el salto de línea */
}
.img-ticket{
    width: 100%;
    max-width: 600px;
}
.ticket-container {
    position: relative;
    display: inline-block;
    margin-top: 20px;
}

.code-container {
    padding: 0 10px;
    margin-top: 20px;
    border-radius: 8px; /* Opcional: redondea las esquinas */
    text-align: center; /* Centra el contenido dentro del contenedor */
}

.subtitle {
    color: #6b7280;
    font-size: 14px;
    margin-bottom: 10px;
}

.button-container-copy{
    display: flex;
    justify-content: center;
}

.code {
    font-size: 24px;
    font-weight: bold;
    color: #3366ff;
    padding: 10px;
    display: inline-block;
    letter-spacing: 2px;
    border: 2px dashed #d1d5db; /* Borde punteado para todo el contenedor */

}

.copy-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    margin-left: 10px;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    transition: color 0.2s ease;
}

.copy-button:hover {
    color: #0056b3;
}

@media (max-width: 768px) {
    .container-cupon{
        max-width: 300px;
        margin: 0 auto;
    }
    .ticket-text{
        font-size: 15px;
        max-width: 200px;
        
    }
    .ticket-container{
        margin-top: 50px;
    }

    .code-container{
        margin-top: 70px;
        padding-bottom: 0;
    }
    .img-ticket{
        max-width: 1000px;
        transform: scale(1.5); /* Aumenta ligeramente el tamaño */
    }
    .button-container-copy{
        display: inline-block;
        justify-content: center; /* Centra el botón horizontalmente */
        align-items: center; /* Centra el botón verticalmente, si es necesario */

    }
    .copy-button{
        margin: 40px auto;
        transform: scale(2.2); /* Ajusta el tamaño del botón en responsive */
    }
}