premio-ruleta-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins","Lato","serif","sans-serif";
}

.container-selector {
    max-width: 500px;
    margin: auto auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.message {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
}

.highlight {
    color: #007bff; /* Color azul */
}

.subtext {
    color: #777;
    font-size: 0.9em;
    margin-bottom: 15px;
}

/* Contenedor de opciones con scroll */
.course-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 270px; /* Limita la altura para activar el scroll */
    overflow-y: auto; /* Habilita el desplazamiento vertical */
    padding-right: 10px; /* Espacio para evitar que el scroll tape el texto */
}

/* Estilo de los botones de opción */
.course-option {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.course-option:hover {
    background-color: #0056b3; /* Azul más oscuro */
}

.course-option:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3);
}

/* Botón de confirmación */
.confirm-button {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #28a745; /* Color verde */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm-button:hover {
    background-color: #218838; /* Verde más oscuro */
}

/* Contenedor para el curso seleccionado */
.selected-course {
    margin-top: 20px;
    padding: 10px;
    background-color: #e9ecef;
    border-radius: 5px;
    font-size: 1em;
    color: #333;
}

.selected {
    background-color:#00c1ff  ; /* Color de fondo para la opción seleccionada */
}

/* Estilo del scroll personalizado (opcional) */
.course-options::-webkit-scrollbar {
    width: 6px;
}

.course-options::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 5px;
}

.course-options::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
