@import "../../sass/index.scss";


@media screen and (max-width:405px){
    .work-item{
        flex-direction: row;
        &-img{
            align-self: center;
        }
        
        &-details{
            margin: 10px 0;
            h6{
                font-size: .8rem;
            }
            p{
                font-size: 10px;
            }
        }
    }
}