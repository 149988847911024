@import '../../sass/index.scss';

.Footer {
  width: 100%;
  background-color: $light;
  color: $color-primary;
  padding: 1.5rem 0;
  font-weight: 500;
  text-align: right;
  a {
    color: $primary;
  }
}