@import '../../../sass/index.scss';

.basic-menu {
  .card {
    background-color: #fff;
  }
  .btn-perfil {
    border: none;
    text-align: left;
    padding: 0 10px;
    text-transform: unset;
    cursor: pointer;
  }

  .materialIconStyle {
    clear: right;
    font-size: 20px;
    float: left;
    transform: none;
    margin-top: 2px;
  }

  .imgPerfil {
    border-radius: 100%;
    border: 1px solid rgba(40,49,74,.5);
    margin: auto;
    width: 120px;
    height: 120px;
  }
  .list-group-item {
    img {
      width: 23px;
      height: 23px;
    }
    .enlaceListGroup {
      text-align: left;
      background: white;
      display: block;
      color: rgb(40, 49, 74);
      text-decoration: none;
      padding: 1px 30px;
      border-radius: 5px;
      outline: none;
      transition: background 0.2s ease-out;
      -webkit-transition: background 0.2s ease-out;
      -moz-transition: background 0.2s ease-out;
      -ms-transition: background 0.2s ease-out;
      -o-transition: background 0.2s ease-out;
    }
    
  }
  

  @media(max-width: 768px){
    .card.img{
        display: none;
    }
}
}

a.enlaceListGroup {
  text-decoration: none;  /* Eliminar el subrayado */
  color: inherit;
}