/* MIXINS */

@mixin btn-degrade {
    .btn{
        // Degrado de #1C56FF a #600FFC 90° Lineal
        color:$full-light;
        background: #1C56FF !important;

        border-radius: 15px;
        font-weight: bold;
        
        &:hover{
            cursor: pointer !important;
            color: $full-light;
            font-size: .75rem;
            background: #113cbb !important;

        }
    }
}

@mixin btn-degrade-custom($size) {
    .btn{
        // Degrado de #1C56FF a #600FFC 90° Lineal
        color:$full-light;
        background: #1C56FF !important;
        background: -webkit-linear-gradient(to left, #1C56FF, #600FFC) !important;
        background: linear-gradient(to left, #1C56FF, #600FFC) !important;
        border-radius: 15px;
        font-weight: bold;
        
        &:hover{
            cursor: pointer !important;
            color: $full-light;
            font-size: $size;
            background: #1C56FF !important;
            background: -webkit-linear-gradient(to right, #1C56FF, #600FFC) !important;
            background: linear-gradient(to right, #1C56FF, #600FFC) !important;
        }
    }
}

