@import '../../sass/index.scss';

.reglas-normas {
  #accordionExample h6.mb-0.font-weight-bold{
    font-size: 12px !important;
  }
  .content-preguntas{
      min-height: 500px;
  }
  .card-header {
    background-color: #fff;
  }
  .card-header h6 a{
      font-size: 12px;
      font-weight: 300;
      color: #666 !important;
  }

  #collapseFour p {
      font-size: 13px !important;
  }

  .contenido {
    padding: 3px 5px;
  }

  .sub-title {
    font-weight: bold !important;
    color: #333;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    
  }
  .sub-title::after {
    content: ' ';
    width: 30px;
    height: 2px;
    margin: auto;
    background-color: #333;
    position: absolute;
    left: 0;
    right: 0;
  }
  .collapsible-link {
    &::after {
      content: '';
      width: 2px;
      height: 12px;
      background: #666 !important;
      position: absolute;
      top: calc(50% - 7px);
      right: calc(1rem + 6px);
      display: block;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
    }
    &::before {
      content: '';
      width: 12px;
      height: 2px;
      background: #666 !important;
      position: absolute;
      top: calc(50% - 2px);
      right: 1.05rem;
      display: block;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
    }
  }
}