@import '../../../sass/index.scss';

.burger-menu-header{
  display: flex;
  justify-content: space-around;
  background-color: $primary;
  color: $full-light;
  .burger-menu-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0;
    }
    span{
      font-size: .5rem;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  
}

.burger-list{
  &-item{
    justify-content: space-between;
    margin-bottom: 8px;
    img{
      margin-left: 15px;
    }
    &:hover {
      span,a{
        color: $full-light;
      }
    }
  }
  &-item-btn{
    width: 100%;
    align-self: center;
    @include btn-degrade-custom(1.1rem);
    button{
      font-size: 1rem;
      width:100%;
    }
  }
}

.burger-list-item{
  cursor: pointer;
}

.badge-color-gestora-success{
  background: #29d698;
  color:#fff;
}

.menuButton{
  margin-right: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color:#000;
  cursor: pointer;
}

.menuButtonNav{
 
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color:#fff;
  cursor: pointer;
}
.basic-menu {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: center;
  .card {
    background-color: #fff;
  }
  .btn-perfil {
    border: none;
    text-align: left;
    padding: 0 10px;
    text-transform: unset;
    cursor: pointer;
  }

  .materialIconStyle {
    clear: right;
    font-size: 20px;
    float: left;
    transform: none;
    margin-top: 2px;
  }

  .imgPerfil {
    border-radius: 100%;
    border: 1px solid rgba(40,49,74,.5);
    margin: auto;
    width: 120px;
    height: 120px;
  }
  .list-group-item {
    border:none;
    img {
      width: 23px;
      height: 23px;
    }
    .enlaceListGroup {
      text-align: left;
      display: block;
      background-color: transparent;
      color: rgb(40, 49, 74);
      text-decoration: none;
      padding: 1px 30px;
      border-radius: 5px;
      outline: none;
      transition: background 0.2s ease-out;
      -webkit-transition: background 0.2s ease-out;
      -moz-transition: background 0.2s ease-out;
      -ms-transition: background 0.2s ease-out;
      -o-transition: background 0.2s ease-out;
    }
  }
  

  @media(max-width: 768px){
    .card.img{
        display: none;
    }
}
}




/* Sweep to right effect */
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  border-radius: 5px;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(94, 134, 253);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  p,a,span{
    color: $full-light;
  }
  
  img{
    z-index: 1;
  }
}
.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}