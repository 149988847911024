/* Notification Component Styles */

.notification-container {
    margin-right: 15px;
}

/* Notifications */

.notification {
    display: inline-block;
    position: relative;
    padding: 6px;
    background: #282828;
    border-radius: 45px;
    font-size: 1.3em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.notification::before, 
.notification::after {
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.notification::before {
    display: block;
    font-family: "FontAwesome";
    transform-origin: top center;
}

.notification::after {
    font-family: Arial;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    top: -9px;
    right: -15px;
    padding: 5px 8px;
    line-height: 100%;
    border: 1px #fff solid;
    border-radius: 60px;
    background: #2b0462;
    opacity: 0;
    content: attr(data-count);
    opacity: 0;
    transform: scale(0.5);
    transition: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.notification.notify::before {
    animation: ring 1.5s ease;
}

.notification.show-count::after {
    transform: scale(1);
    opacity: 1;
}

.notification-info-panel {
    max-height: 300px;
    overflow-y: auto;
    padding: 0;
}

.notification-info-panel .notification-message {
    list-style: none;
    padding: 4px;
    background-color: #ebebeb;
    margin-bottom: 3px;
    border: 1px solid #ececec;
    border-radius: 8px;
}

.notification-info-panel .notification-message .timestamp {
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 600;
}

.notification-info-panel .notification-message .content {
    font-size: 17px;
}

.notification-info-panel .notification-message .content a {
    color:#29d698;
    font-weight: bold;
}

.notification-info-panel .notification-message.unread {
    background-color: #2b0462;
    color: #FFFFFF;
}