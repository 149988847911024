@import '../../sass/index.scss';

@mixin colorTitle($color: $primary) {
  border-bottom: 1px dotted $color;
  h4 {
    color: $color;
  }
}

.TitlePagina {
  width: 100%;
  h4 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 1px;
  }
  &.lg h4 {
    font-size: 1.3rem;
  }
  &.primary {
    @include colorTitle($primary);
  }
  &.secondary {
    @include colorTitle($secondary);
  }
}