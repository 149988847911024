@import '../../sass/index.scss';

.PerfilUsuario {
    color: $color-primary;
    // background-color: #fff;
    // box-shadow: $style-shadow;
    width: 100%;
    input,
    textarea{
        background: #fff;
        font-size: 13px;
        font-weight: 400;

    }

    label {
        font-size: 13px;
        font-weight: 500;
        text-align: right;
        @media (max-width: 614px) {
            & {
                text-align: left;
            }
        }
    }
    .btn-submit {
        padding: 6px 12px;
        font-size: 14px;
    }
    .custom-file-control{
        background: $primary;
        color: #fff;
    }

    .custom-file-control:hover{
        color: #fff;
    }
}