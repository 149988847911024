.accordion {
  .card {
    margin-bottom: 15px;
    &:not(:first-of-type) {
      border-radius: 5px;
    }
    &-header {
      background-color: $primary;
      color: #fff;
      font-size: .9rem;
      svg {
        margin-right: 5px;
      }
    }
    &-body {
      @media (max-width: 723px) {
        padding: 10px;
      }
    }
  }
}