@import "../../sass/index.scss";

.Login {
	background-color: #FDFDFC;
  width: 100%;
	height: 100vh;
	&__container {
		width: 100%;
		height: 100%;
	}
	.row {
		margin-left: 0;
		margin-right: 0;
	}
  &__left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 10vh 5vw 5vh 5vw;
    img {
      max-width: 18vw;
      max-height: 10vh;
			margin-left: -8px;
			@media (max-width: 414px) {
				width: 50%;
				margin-left: -17px;
				max-width: 100vw;
				max-height: 12vh;
			}
    }
    h4 {
      font-size: 1.3rem;
      margin-top: 3rem;
      margin-bottom: 2.5rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $color-primary;
			width: 89%;
			@media (min-width: 1280px) {
				& {
					font-size: 1.8rem;
				}
			}
		}
		@media (max-width: 614px) {
			& {
				padding: 3vh 5vw 5vh 5vw;
			}
		}
	}
	&__sociales {
		&__mensaje {
			color: $color-light;
			position: relative;
			font-size: .8rem;
			font-weight: 500;
			&::after,
			&::before {
				content: '';
				position: absolute;
				width: 18%;
				border-top: 3px solid lighten($color: $color-light, $amount: 20);
				height: 2px;
				top: 50%;
			}
			&::after {
				transform: translateX(20%);
			}
			&::before {
				transform: translateX(-120%);
			}
			@media (max-width: 980px) {
				&::after,
				&::before {
					content: none;
				}
			}
		}
		&__imgs {
			margin-top: 15px;
			.img-sociales-content {
				margin-left: 1.5rem;
				text-align: center;
			}
			.img-sociales-content:first-child {
				margin-left: 0;
			}
			img {
				width: 40px;
				height: 40px;
				@media (min-width: 1280px) {
					& {
						width: 50px;
						height: 50px;
					}
				}
			}
		}
	}

	&__rigth #ReactBackgroundSlider figure {
		z-index: 1;
	}

	@media (max-width: 980px) {
		&__rigth #ReactBackgroundSlider {
			display: none;
		}
	}
}
