@import '../../sass/colores.css';

.tr-sesion{
    color: #000 !important;
}

.tr-sesion .p-click {
    color: #fff;
    background-color: var(--main-success-color);
    font-size: 10px;
    height: 30px;
}