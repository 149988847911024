@import "../../sass/index.scss";
body{
	background-color: #f3f6fd !important;
	
}
.main-section {
	background-color: $body-bg-secondary;
	top: 0;
	h2{
		color: #1C56FF;
		font-size: 32px;
		font-weight: 500;
		@media (max-width: 768px) {
			font-size: 27px;	
		  }
	}
	.saludo-usuario{
		color: black;
		font-weight: 500 !important;
	}
	h5 {
		color: $body-text-primary;
	}
}

.hero-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background: url("/images/assets/fondo_ruleta.png") no-repeat center;
	background-size: cover;
	max-width: 100%;
	height: 400px;
	.hero-content {
		margin: 0 40px;
		p {
			color: #fff;
			font-size: 3.5rem;
			text-align: center;
		}
		@media screen and (max-width: 545px) {
			p {
				font-size: 2rem;
			}
		}
		
	}
	.hero-image-container {

		img {
			max-width: 500px;
		}
	}
}

.remember-section {
	.remember-container {
		background-color: $full-light;
		padding: 20px;
		border-radius: 30px;
		
		.remember-item {
			display: flex;
			align-items: center;
			justify-content: space-around;
			
			&-icon {
				display: flex;
				align-items: center;
			}
			&-content {
				display: flex;
				align-items: center;
				font-size: 12px;
				span {
					font-size: 13px;
				}
				
				.calendar-container {
					display: flex;
					padding: 0;
					.calendar-icon {
						max-width: 20px;
						margin-right: 10px;
					}
					span {
						color: $primary;
					}
				}
			}
		}
	}
}

.remember-icons {
	max-width: 50px;
}


.card-next-sesions{
	margin: 10px 50px;
	border-radius: 25px;
}
.card-list-works{
	border-radius: 5px 30px 5px 5px;
	border: none;

}


.card-list-works{
	
	.work-item{
		// Degrado de #1C56FF a #600FFC 90° Lineal
		color: black;
		border-radius: 15px;
		border: none;
		// font-weight: bold;
		p{
			font-size: 17px;
			color: #1C56FF;
			font-weight: bold;
		}
		h6{
			font-size: 16px;
		}
		
		&:hover{
			cursor: pointer !important;
			font-size: .75rem;
			-webkit-box-shadow: 2px 3px 11px -5px rgba(0,0,0,0.75);
			-moz-box-shadow: 2px 3px 11px -5px rgba(0,0,0,0.75);
			box-shadow: 2px 3px 11px -5px rgba(0,0,0,0.75);

		}
	}
	
	.work-actions{
		display: flex;
		align-items: center;
		justify-content: right;
		@include btn-degrade;
	}
}

.next-class-container {
	max-width: 1250px;
	margin: 0 auto;
	padding: 20px;
	@media screen and (max-width: 768px) {
		padding: 10px 0;
		
	}
}

.next-class-card {
	background-color: $full-light;
	border-radius: 18px;
	padding: 35px 50px;
	@media screen and (max-width: 768px) {
		padding: 20px 30px;
		
	}
  }

  .react-multi-carousel-dot-list{
	  display: flex;
	  justify-content: center;
	  z-index: 1;
	  li{
		  margin: 3px;
		  button{
			  border-radius: 50%;
			  width: 10px;
			  height: 10px;
			  border: 1px solid #1C56FF;
			  &:hover{
				  background-color: #1C56FF;
			  }
			  @media (max-width: 768px) {
				width: 15px;
				height: 15px;
			  }
		  }
	  }
	  // Añade esto para el dot acti
  }
  .react-multi-carousel-dot--active button{
	  background: #1C56FF !important;
  }

  


 