
.cabecera_modulos{
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/mo_header_3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-height:280px;
  width: 100%;
  position: relative;
}

.tab-content.sesiones {
  .title-tabla {
    font-weight: bold;
  }
  table {

    @media (max-width: 414px) {
      .th-tema {
        min-width: 500px;
        max-width: 800px;
      }
      .th-fecha {
        min-width: 120px;
      }
    }
  }
} 