
$primary: #1C56FF;
$secondary: #3A4C7E;
$success: #18BB60;
$info: #3497DB;
$warning: #F29F06;
$danger: #1C56FF;
$bg-primary: #1C56FF;
$body-bg-secondary: #f3f6fd;
$body-text-primary: #474747;

 /*#E74C3C;*/
$light: #f5f5f5;
$full-light: #fff;
    //Sombras
$style-shadow: 0 .125rem .25rem rgba(0,0,0,.075); 

$color-primary: #383838;
$color-light: #858583;