@import '../../sass/colores.css';

/*GENERAL*/
.nav-link.tab-a.d-block.active.show{
  background: var(--main-danger-color);
}

.resumen-title h4{
  font-size: 14px !important;
}
.thead-cuenta {
  border-color: var(--main-primary-color);
  background: var(--main-primary-color);
}

.title{
  color: var(--main-primary-color);
  border-color: var(--main-primary-color);
}
.content-header{
  border-bottom: 3px dotted;
}
/*Modulos*/
.resumen-title{
  background: var(--main-primary-color);
  
}
.resumen-title h4{
  font-size: inherit;
}
.content-body-proyecto .content-info .text-label{
  color: var(--main-primary-color);
}


.asistencia-nulo{
  margin-top: 15px;
  margin-left: 10px;
  padding: 10px;
}
.modulo-header h4{
  font-size: 14px;
}
.__detalles-modulo p.label,
.detalles-asistencia p.label {
  color: var(--main-primary-color);
}
.modulo .modulo-header {
  background: var(--main-primary-color);
}

.boton-sesiones a{
  background: var(--main-success-color);
  padding: 6px 20px;
  font-weight: 600;
}

@media (max-width: 414px) {
  .boton-sesiones a{
    margin-bottom: 25px;
  }
  
}


/*Horario*/
.content-modulo-resumen .content-header.title { 

  color: var(--main-secondary-color);
  border-color: var(--main-secondary-color);
}

.thead-horario{
  background: var(--main-secondary-color);
}
.content-estado-cuenta .content-header{
  border-color: var(--main-success-color);
}
.tbody-horario{
  font-size: 13px;
}
.content-modulo-resumen table thead tr th {
  font-size: 13px !important;
  line-height: 15px;
  padding: 7px 5px;
}
.content-modulo-resumen table tbody tr td {
  font-size: 12px !important;
  line-height: 12px;
  padding: 7px 5px;

}

.content-horario-agenda .content-header {
  color: var(--main-secondary-color) !important;
  border-color: var(--main-secondary-color);
}

/*PAGOS*/

.content-estado-cuenta .content-header,
.certificado .content-header,
.contancia-estudio .content-header{
  border-color: var(--main-primary-color);
}
.content-estado-cuenta .content-header h4,
.certificado .content-header h4{
  color: var(--main-primary-color);
  padding-left: 5px;
}
.content-estado-cuenta .tabla-cuenta thead tr th {
  padding: 7px 5px;
  font-size: 13px;
  font-weight: 500;
}


.content-estado-cuenta .tabla-cuenta tbody td {
  padding: 7px 5px;
}
.content-estado-cuenta .tabla-cuenta tbody td p {
  font-size: 12px;
  font-weight: 400 !important;
}
.content-estado-cuenta .tabla-cuenta tbody td p.text-info:hover {
  text-decoration: underline;
}
.content-estado-cuenta .thead-cuenta{
  background: var(--main-primary-color);
}
.content-estado-cuenta .img-enlaces{
  display: flex;
  flex-direction: row;
}
.content-estado-cuenta a img {
  width: 100px;
  border-radius: 15px;
  margin-right: 5px;
}


.content-estado-cuenta .content-canales-pago .content-header h4{
  color: var(--main-primary-color);
}

.content-estado-cuenta .content-canales-pago .content-header{
  border-color: var(--main-primary-color);
}


@media (max-width: 414px) {
  .content-modulo-resumen .thead-horario__col-modulos {
    min-width: 300px;
  }
  .content-modulo-resumen .tbody-horario__col-modulos {
    line-height: 15px;
  }
}
  /*Estilos de estado de cuenta*/
.content-body-canales {
  padding: 18px;
}

.content-body-canales .content-lista .titulo {
  font-weight: bold;
  font-size: 13px;
  color: var(--main-success-color);
}

.content-body-canales .content-lista .definicion a{
  margin-left: 7px;
  font-size: 12px;
  color: var(--main-success-color);
}

.content-body-canales .content-lista .content-info {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin: 20px 0
}

.content-body-canales .content-lista .content-info .animate__repeat {
  animation-iteration-count: 1;
}

@media (max-width: 414px) {
  .content-body-canales {
    padding-right: 0px;
  }
  .content-body-canales .content-lista .content-info {
    width: 100%;
  }

  .content-body-canales .content-lista .content-info .descripcion {
    margin-left: 15px;
  }
}

.content-info img {
  width: 110px;
  height: 90px;
  cursor: pointer;
  transition: transform .3s ease;
  -webkit-transition: transform .3s ease;
  -moz-transition: transform .3s ease;
  -ms-transition: transform .3s ease;
  -o-transition: transform .3s ease;
 
}

.content-info img:hover {
  transform: scale(1.05,1.05) !important;
  -webkit-transform: scale(1.05,1.05) !important;
  -moz-transform: scale(1.05,1.05) !important;
  -ms-transform: scale(1.05,1.05) !important;
  -o-transform: scale(1.05,1.05) !important;
}

.content-body-canales .content-lista .content-info .titulo-decrip {
  font-weight: 500;
  font-size: 12px;
}
.content-body-canales .content-lista .content-info .item-decrip {
  display: block;
  margin-left: 20px;
  margin-top: 10px;
}

.content-body-canales .content-lista .content-info .item-decrip li{
  
  margin-bottom: 5px;
  font-size: 12px;
}