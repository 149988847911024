@import '../../sass/index.scss';

.cambiar-password {
    .btn-guardar{
        background: $primary;
    }
    label {
        font-size: 13px;
        font-weight: 500;
    }
    input {
        padding: 19px 10px;
        font-size: 14px;
    }
}
