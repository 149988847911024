@import "../../sass/index.scss";


.sesions-container{

    .calendar-container {
        font-weight: 500;
        font-size: 14px;

        .calendar-icon {
            max-width: 20px;
            margin-right: 10px;
        }
        span {
            color: $primary;
            flex-wrap: nowrap;
        }
    }
    
    .sesions-actions{
        @include btn-degrade;
    }
    


.class-details {

    
}
  
  .class-type {
    font-weight: 500;
    color: #333; 
  }
  
  .class-description {
    color: #555;
    font-size: 0.9rem;
    font-weight: 500;
  }
}

