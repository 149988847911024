@import '../../sass/index.scss';

.error-404 {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  img{
      width: 35%;
      margin: 35px 0;
  }
  a{
      font-size: 20px;
      font-weight: bold;
      color: $primary;
    &:hover {
      cursor: pointer;
      color: $primary;
      text-decoration: underline;
    }
  }

}