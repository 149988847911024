* {
	margin:0;
	padding: 0;
  box-sizing: border-box;
}
html {
  min-height: 100%;
  position: relative;
}
body {
  font-family: "Poppins", "Lato", "serif", "sans-serif";
  background: #FDFDFC;
  margin: 0;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.3rem;
}

p{
  margin-bottom: 0 !important;
}

a{
  outline:none;
}

li{
	font-size: 13px;
}

.bg-success {
  background-color: $success;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.form-control {
  &:focus {
    box-shadow: 0 0 1px 3px #DEE3F8;
  }
  &:active, &:focus {
    text-shadow: 0;
    outline: none !important;
    outline-width: 0 !important;
  }
  &:disabled {
    background-color: #f2f2f2;
  }
}